import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <div>
      <p>{`Power Snatch: 2-2-2-2`}</p>
    </div>
    <div>
    </div>
    <div>
      <p>{`EMOM for 10:00 (@ 70% of your last set of 2)-`}</p>
    </div>
    <div>
      <p>{`1- power snatch`}</p>
    </div>
    <div>
      <p>{`2- overhead squats`}</p>
    </div>
    <div>
      <p>{`1- snatch balance`}</p>
    </div>
    <div>
    </div>
    <div>
      <p>{`rest 5:00, then:`}</p>
    </div>
    <div>
    </div>
    <div>
      <p>{`1 mile run for time`}</p>
    </div>
    <div>
    </div>
    <div>
      <p><strong parentName="p"><em parentName="strong">{`*`}{`Congrats to Meghan Hicks and our newest CrossFit Level 1 Laura
Barito on their 1st place finish at yesterday’s competition at
Bluegrass!`}</em></strong></p>
    </div>
    <div>
    </div>
    <div>
      <div>
        <p><strong parentName="p"><em parentName="strong">{`*`}{`Get your teams together in a hurry for Sept. 14th’s True Grit
Throwdown!  We’re filling up fast but have a few spots left.  First 15
teams to get their applications and \\$ in will get the slots.  If you
have turned in your application but not your \\$ your teams spot can be
filled by someone else.`}</em></strong></p>
      </div>
      <div>
      </div>
      <div>
        <p><strong parentName="p"><em parentName="strong">{`*`}{`Free Labor Day WOD at 8:00am Monday!  Bring a friend!  If 8:00 is
too early we’ll also have a Labor Day WOD at Louisville East at 10:30.
 All other classes will be cancelled on Monday.`}</em></strong></p>
      </div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      